import {createStore} from 'redux'
import AppReducer from 'reducers'

export default function configureStore() {
    return {
        ...createStore(
            AppReducer,
        ),
    }
}
