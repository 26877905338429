import {
    SET_QUESTION
} from 'actions/types'

const initialState = {
    detail: null // 题目详情
}

export default function base(state = initialState, { type, payload }) {
    switch (type) {
        case SET_QUESTION:
            return {
                ...state, detail: payload
            }
        default:
            return state
    }
}
