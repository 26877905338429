import {memo} from 'react'
import {Spin} from 'antd'
import styles from './index.module.less'

export default memo(function PageLoading({tip = 'loading...', size = 'large'}) {
    return (
        <Spin size={size} wrapperClassName={styles.wrapper} spinning tip={tip}>
            <span />
        </Spin>
    )
})
