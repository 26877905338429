import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Suspense, StrictMode } from 'react'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import PageLoading from 'components/PageLoading'
import router from './router'
import constants from 'constants'
import './App.less'
import { MathJaxContext } from "better-react-mathjax"

const store = configureStore()

function LayoutComponent(route) {
    if (route.hasOwnProperty('layout')) {
        return props => <route.layout {...route}>
            <Suspense fallback={<PageLoading />}>
                {route.provider ?
                    <route.provider>
                        <route.component {...props} />
                    </route.provider>
                    :
                    <route.component {...props} />
                }
            </Suspense>
        </route.layout>
    } else {
        return props => <Suspense fallback={<PageLoading />}>
            {route.provider ?
                <route.provider>
                    <route.component {...props} />
                </route.provider>
                :
                <route.component {...props} />
            }
        </Suspense>
    }
}



function App() {
    // const subjectID = window.localStorage.getItem('subject_id')
    return (
        <StrictMode>
            <Provider store={store}>
                <MathJaxContext version={3} config={{ ...constants.latexConfig}}>
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/dashboard" />
                            </Route>
                            {router.map((route, idx) => {
                                return <Route exact={route.exact} path={route.path}
                                              component={LayoutComponent(route)}
                                              key={idx} />
                            })}
                        </Switch>
                    </Router>
                </MathJaxContext>
            </Provider>
        </StrictMode>
    )
}

export default App
