const filterYears = [{ label: "全部", value: 0 }]
for (let i = 0; i < 30; i++) {
    let cYear = new Date().getFullYear()
    filterYears.push({
        label: cYear - i,
        value: cYear - i,
    })
}
const constants = {
    env: process.env.REACT_APP_MOMO_ENV,
    host: process.env.REACT_APP_MOMO_ENV === "develop" ? "" : "",
    dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    dateFormat: "YYYY-MM-DD",
    dateIDFormat: "YYYYMMDD",
    splitSyn: "❗",
    pageSizeRange: [10, 20, 30, 40, 50, 100, 500, 1000, 2000, 3000],
    wordType: {
        noun: {
            type: "proper_noun",
            name: "专有名词",
        },
        abbreviation: {
            type: "abbreviation",
            name: "缩写词",
        },
        phrase: {
            type: "phrase",
            name: "词组",
        },
        alias: {
            type: "alias",
            name: "别名",
        },
        compound: {
            type: "compound",
            name: "复合词",
        },
    },
    source: {
        zujuan_paper: "组卷网试卷",
        zujuan_exercise: "组卷网习题",
        manual: "人工添加",
    },
    questionTypeDesc: {
        1: "单项选择",
        2: "简答题",
        4: "阅读理解",
        5: "完型填空",
        6: "阅读填空",
        7: "听力选择",
        8: "听短文选择",
        9: "听力排序",
        10: "听写",
        11: "音标跟读",
        12: "单词跟读",
        13: "单词解释",
        14: "单词拼写",
        15: "字母",
    },
    levelType: {
        english_listening: "英语听力",
        english_reading: "英语阅读",
        classical_chinese_reading: "文言文阅读",
        modern_chinese_reading: "现代文阅读",
    },
    letter: ["A", "B", "C", "D", "E", "F", "G", "H"],
    pos: {
        Web: "网络",
        "n.": "名词,Nouns (n.) 表示人bai或事物的名称du box,pen,tree,apple",
        "pron.": "代替名词、数词、形容词baiWe,this,them,myself",
        "adj.": "形容词,Adjectives(adj.) 用来修饰名词,表示人或事bai物的特征 good,sad,high,short",
        "num.": "数词,Numerals(num.)表示数目或顺序 one,two,first",
        "v.": "动词,Verb (v.) 表示动作或状态 Jump,sing,visit",
        "adv.": "副词,Adverbs（adv.) 修饰动、形、副等词,表示动作特征 there,widely,suddenly",
        "art.": "冠词,Articles (art.) 用在名词前,帮助说明名词所指的范围 a,an,the",
        "prep.": "介词,Prepositions (prep.) 用在名词或代词前,说明它与别的词的关系 in,on,down,up",
        "conj.": "连词,Conjunctions (conj.) 表示人或事物的名称if,because,but",
        "int.": "感叹词,Interjections (int.) 代替名词、数词、形容词等 oh,hello,hi,yeah",
        "vt.": "及物动词,vt.后必须跟宾语：sing a song",
        "vi.": "不及物动词,vi.后不直接带宾语或不带宾语:jump high",
    },
    runMode: {
        once: "一次",
        interval: "定期",
        crontab: "计划任务",
    },
    skillType: [
        {
            id: "common",
            name: "普通",
        },
        {
            id: "reading",
            name: "阅读",
        },
        {
            id: "listening",
            name: "听力",
        },
        {
            id: "writing",
            name: "写作",
        },
    ],
    latexConfig: {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"],
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"],
            ],
        },
    },
    statusDesc: {
        0: "未知",
        1: "未学习",
        2: "未完成",
        3: "已完成",
    },
    finishStatus: {
        0: "未开始",
        1: "完成",
        2: "进行中",
    },
    suspendDesc: {
        0: "否",
        1: "用户挂起",
        2: "后台挂起",
        "-1": "失败",
    },
    relationTypes: [
        {
            value: "",
            label: "全部",
        },
        {
            value: 0,
            label: "通用卡片",
        },
        {
            value: 1,
            label: "英语单词",
        },
        {
            value: 2,
            label: "英语音标",
        },
        {
            value: 3,
            label: "英语字母",
        },
        {
            value: 4,
            label: "英语课文",
        },
        {
            value: 5,
            label: "汉语字词",
        },
        {
            value: 6,
            label: "素材",
        },
    ],
    chargeTypes: [
        {
            value: 1,
            label: "充值学习豆",
        },
        {
            value: 2,
            label: "开通学生账号",
        },
        {
            value: 3,
            label: "月费",
        },
        {
            value: 4,
            label: "启用学生账号",
        },
    ],
    grades: [
        {
            value: 1,
            label: "一年级",
        },
        {
            value: 2,
            label: "二年级",
        },
        {
            value: 3,
            label: "三年级",
        },
        {
            value: 4,
            label: "四年级",
        },
        {
            value: 5,
            label: "五年级",
        },
        {
            value: 6,
            label: "六年级",
        },
        {
            value: 7,
            label: "七年级",
        },
        {
            value: 8,
            label: "八年级",
        },
        {
            value: 9,
            label: "九年级",
        },
        {
            value: 10,
            label: "高一",
        },
        {
            value: 11,
            label: "高二",
        },
        {
            value: 12,
            label: "高三",
        },
        {
            value: 0,
            label: "其它",
        },
    ],
    terms: [
        {
            value: 0,
            label: "未知",
        },
        {
            value: 1,
            label: "上学期",
        },
        {
            value: 2,
            label: "下学期",
        },
    ],
    courseTypes: [
        {
            value: 1,
            label: "文本",
        },
        {
            value: 2,
            label: "图片",
        },
        {
            value: 3,
            label: "视频",
        },
        {
            value: 4,
            label: "语音",
        },
        {
            value: 5,
            label: "GPT题目",
        },
        {
            value: 998,
            label: "批量GPT题目",
        },
        {
            value: 6,
            label: "GPT提问",
            // }, {
            //     value: 8,
            //     label: 'GPT语音'
        },
        {
            value: 7,
            label: "跟读",
        },
        {
            value: 999,
            label: "选择课本",
        },
    ],
    courseTypeValue: {
        text: 1,
        image: 2,
        video: 3,
        audio: 4,
        gpt: 5,
        gptAsk: 6,
        gptAudio: 8,
        gptFollowReading: 7,
        batchGpt: 998,
        textFromBook: 999,
    },
    courseTypeDesc: {
        1: "文本",
        2: "图片",
        3: "视频",
        4: "音频",
        5: "GPT题目",
        6: "GPT提问",
        7: "跟读",
        8: "GPT语音",
        999: "课本",
    },
    learningTypeDesc: {
        skill: "刷题",
        knowledge: "学习",
        course: "课程",
        early: "提前复习",
        repeat: "课文跟读",
        level: "微课",
    },
    inputTypes: [
        {
            id: 0,
            name: "打字",
        },
        {
            id: 1,
            name: "语音",
        },
        {
            id: 2,
            name: "聊天",
        },
    ],
    inputTypeDesc: {
        0: "打字",
        1: "语音",
        2: "聊天",
    },
    tplType: {
        0: "提示词",
        1: "文本",
        2: "详解",
    },
    levelContentType: {
        1: "GPT课程",
        2: "卡片组",
        3: "课文跟读",
        4: "能力点",
        5: "视频",
    },
    paperTypeKV: {
        1: "课时练习",
        2: "单元测试",
        3: "阶段练习",
        4: "期中",
        5: "期末",
        6: "专题练习",
        7: "竞赛",
        8: "开学考试",
        10: "中考模拟",
        13: "中考真题",
        15: "学业考试",
        31: "课前预习",
        100: "零模",
        101: "一模",
        102: "二模",
        103: "三模",
        104: "模拟预测",
        0: "无",
    },
    paperTypes: [
        {
            value: 31,
            label: "课前预习",
        },
        {
            value: 1,
            label: "课时练习",
        },
        {
            value: 2,
            label: "单元测试",
        },
        {
            value: 3,
            label: "阶段练习",
        },
        {
            value: 4,
            label: "期中",
        },
        {
            value: 5,
            label: "期末",
        },
        {
            value: 10,
            label: "中考模拟",
        },
        {
            value: 13,
            label: "中考真题",
        },
        {
            value: 15,
            label: "学业考试",
        },
        {
            value: 8,
            label: "开学考试",
        },
        {
            value: 6,
            label: "专题练习",
        },
        {
            value: 7,
            label: "竞赛",
        },
        {
            value: 100,
            label: "零模",
        },
        {
            value: 101,
            label: "一模",
        },
        {
            value: 102,
            label: "二模",
        },
        {
            value: 103,
            label: "三模",
        },
        {
            value: 104,
            label: "模拟预测",
        },
    ],
    wordSource: {
        manual: "人工添加",
        user: "学生查询",
        system: "系统生成",
    },
    accountType: {
        0: "注册版",
        1: "收费版",
        2: "完整版",
    },
    grammarElements: [
        "coordinating conjunctions",
        "subordinating conjunctions",
        "correlative conjunctions",
        "conjunctive adverbs",
        "subject",
        "verb",
        "object",
        "adjective",
        "adverbial",
        "complement",
        "subject clauses",
        "object clauses",
        "complement clauses",
        "appositive clauses",
        "relative clauses",
        "adverbial clauses",
        "prepositional phrases",
        "infinitive phrases",
        "gerund phrases",
        "participial phrases",
        "adjective phrases",
        "adverb phrases",
        "noun phrases",
    ],
    tplKeys: ["passage", "sentence_en", "sentence_cn", "sentences", "question", "options", "options_cn", "answer", "word", "sentence_en_words", "nlp_words_str", "sentence"],
    years: filterYears,
    chapter: [
        { label: "全部", value: "" },
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
        { label: "E", value: "E" },
    ],
    replyTypes: [
        { value: 0, label: "提示词" },
        { value: 1, label: "文本" },
        { value: 2, label: "详解" },
        { value: 3, label: "快捷回复" },
    ],
    publish: [
        { label: "已发布", value: 1 },
        { label: "未发布", value: 2 },
    ],
    explainWordKeyOrder: ["word", "translation", "explanation", "baseform", "sentence_en", "sentence_cn", "option_index", "start"],
    explainPhraseKeyOrder: ["phrase", "translation", "explanation", "sentence_en", "sentence_cn"],
    creditType: { 1: "充值", 2: "消费", 3: "赠送" },
    yesNoOptions: [
        { label: "是", value: 1 },
        { label: "否", value: 0 },
    ],
    explainRegExp: "explainRegExp",
    explainReplaceTranslation: "explainReplaceTranslation",
    explainIgnoreStrongWords: "explainIgnoreStrongWords",
}

export default constants
