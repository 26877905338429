/**
 * 文件说明: 常量接口
 * ----------------------------------------
 * 创建用户: liyan
 * 创建日期: 11/12/21
 */
import { formatUrl, request } from "./index"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // 题目听力处理状态列表
    listenStatuses: async () => {
        return request(formatUrl("/const/question_listen_statuses", null, false))
    },
    // 年级目列表
    models: async () => {
        return request(formatUrl("/const/models", null, false))
    },
    // 频道来源
    channelSources: async () => {
        return request(formatUrl("/const/channel_sources", null, false))
    },
    // 问题反馈码
    feedbackCodes: async () => {
        return request(formatUrl("/const/feedback_codes", null, false))
    },
    // 问题反馈码
    knowledgeFeedbackCodes: async () => {
        return request(formatUrl("/const/feedback_codes", null, false))
    },
    // 语言列表
    languages: async () => {
        return request(formatUrl("/const/languages", null, false))
    },
    // 题库模式列表
    exerciseModes: async () => {
        return request(formatUrl("/const/exercise_modes", null, false))
    },
    // 地区列表
    areas: async () => {
        return request(formatUrl("/const/areas", null, false))
    },
    // 地区列表
    standards: async () => {
        return request(formatUrl("/const/standards", null, false))
    },
    // 试卷类型
    paperTypes: async () => {
        return request(formatUrl("/const/paper_types", null, false))
    },
    // 时区列表
    timezones: async () => {
        return request(formatUrl("/const/timezones", null, false))
    },
    // 用户版本类型
    versionTypes: async () => {
        return request(formatUrl("/const/version_types", null, false))
    },
    // 客户端列表
    clients: async () => {
        return request(formatUrl("/const/clients", null, false))
    },
    // 平台列表
    platforms: async () => {
        return request(formatUrl("/const/platforms", null, false))
    },
    // 科目列表
    subjects: async () => {
        return request(formatUrl("/const/subjects", null, false))
    },
    // 年级目列表
    grades: async () => {
        return request(formatUrl("/const/grades", null, false))
    },
    // 册数列表
    terms: async () => {
        return request(formatUrl("/const/terms", null, false))
    },
    // 阶段列表
    stages: async () => {
        return request(formatUrl("/const/stages", null, false))
    },
    // 阶段列表
    questionSources: async () => {
        return request(formatUrl("/const/question_sources", null, false))
    },
    //  试题 状态
    questionHandleStatuses: async () => {
        return request(formatUrl("/const/question_handle_statuses", null, false))
    },
    //  试题类型
    questionTypes: async () => {
        return request(formatUrl("/const/question_types", null, false))
    },
    //  题库试卷类型列表
    getExerciseTypes: async () => {
        return request(formatUrl("/const/exercise_types", null, false))
    },
    //  中文词汇类型列表
    chineseWordTypes: async () => {
        return request(formatUrl("/const/chinese_word_types", null, false))
    },
    //  知识点(卡片)类型列表
    knowledgeQuestionType: async () => {
        return request(formatUrl("/const/knowledge_question_types", null, false))
    },
    //  知识点(卡片)关联类型列表
    relationTypes: async () => {
        return request(formatUrl("/const/relation_types", null, false))
    },
    //  知识点(卡片)类型列表
    skillType: async () => {
        return request(formatUrl("/const/skill_types", null, false))
    },
    //  配置
    levels: async () => {
        return request(formatUrl(`/const/rank_levels`, null, false))
    },
    //  难度级别
    diffLevels: async () => {
        return request(formatUrl(`/const/diff_levels`, null, false))
    },
    //  主题列表 阅读
    topics: async () => {
        return request(formatUrl(`/const/topics`, null, false))
    },
    //  语篇列表
    genres: async () => {
        return request(formatUrl(`/const/genres`, null, false))
    },
    //  知识点测试关联类型列表
    getQuestionRelationTypes: async () => {
        return request(formatUrl(`/const/question_relation_types`, null, false))
    },
    //  配置
    getConfig: async (params) => {
        return request.get(formatUrl("/configs", null, false), { params })
    },
    //  修改配置
    putConfig: async (_key, _value) => {
        return request.put(formatUrl(`/configs/${_key}`, null, false), { value: _value })
    },
    //  模型列表
    getPromptProviders: async () => {
        return request.get(formatUrl(`/const/prompt_template_providers`, null, false), {})
    },
}
