import { formatUrl, request } from './index'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getPromptTemplates: async (params) => {
        return request(formatUrl(`/prompt_templates`), { params })
    },

    postPromptTemplates: async (data) => {
        return request.post(formatUrl(`/prompt_templates`), data)
    },
    putPromptTemplates: async (_id, data) => {
        return request.put(formatUrl(`/prompt_templates/${_id}`), data)
    },
    deletePromptTemplates: async (_id) => {
        return request.delete(formatUrl(`/prompt_templates/${_id}`), { data: {} })
    }
}
