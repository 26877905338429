import {combineReducers} from 'redux'
import base from './base'
import question from './question'

const AppReducer = combineReducers({
    base,
    question
})

export default AppReducer

