import {
    SET_CHANNEL_SOURCES,
    SET_FEEDBACK_CODES,
    SET_KNOWLEDGE_QUESTION_TYPES,
    SET_QUESTION_TYPES,
    SET_LANGUAGES,
    SET_RELATION_TYPES,
    SET_SKILL_TYPES,
    SET_SUBJECTS,
    SET_ORG_LIST,
    SET_ZONE_LIST,
    SET_QUESTION_RELATION_TYPES,
    SET_PROMPT_TPL,
    SET_AREA,
    SET_EXERCISES,
    SET_EXERCISE_TYPE,
    SET_KV,
    SET_CONSTANTS,
} from "actions/types"

const initialState = {
    channelSources: [], // 频道来源
    feedbackCodes: [], // 反馈错误码
    knowledgeTypes: [], // 卡片类型
    questionTypes: [], // 题型
    questionRelationTypes: [], // 知识点测试关联类型列表
    languages: [], // 语言列表
    subjects: [], // 科目列表
    skillType: [], // 能力点类型
    promptTpl: [], // 模板列表
    area: [], // 地区
    exerciseTypes: [], // 题库试卷类型列表
    KV: {
        area: {},
        topics: {},
        genres: {},
        diffLevels: {},
        exerciseTypes: {},
        exerciseModes: {}, // 题库模式列表
        standards: {}, // 标准
        paperTypes: {}, // 标准
        versionTypes: {}, // 标准
        questionHandleStatuses: {}, // 题目听力素材处理状态
        listenStatuses: {},
    }, // 全局KV
    constants: {
        wordTags: [], // 单词标签
        models: [], // 模型
        paperTypes: [], // 试卷类型
        versionTypes: [], // 试卷类型
        exerciseModes: {}, // 题库模式列表
        questionHandleStatuses: [], // 题目处理状态
        listenStatuses: [], // 题目听力素材处理状态
    },
    exercises: {
        topics: [], // 主题语境
        genres: [], //语篇列表
        diffLevels: [], // 难度等级
        standards: [], // 中高习题标准
        types: [], // 试卷类型
        // modes:[], // 题库模式列表
    }, // 练习册相关配置
}

export default function base(state = initialState, { type, payload }) {
    switch (type) {
        case SET_CHANNEL_SOURCES:
            return {
                ...state,
                channelSources: payload,
            }
        case SET_FEEDBACK_CODES:
            return {
                ...state,
                feedbackCodes: payload,
            }
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: payload,
                subjectsKV: payload.map((p) => ({ [p.id]: p.name })),
            }
        case SET_KNOWLEDGE_QUESTION_TYPES:
            return {
                ...state,
                knowledgeTypes: payload,
            }
        case SET_RELATION_TYPES:
            return {
                ...state,
                relationTypes: payload,
            }
        case SET_SKILL_TYPES:
            return {
                ...state,
                skillTypes: payload,
            }
        case SET_LANGUAGES:
            return {
                ...state,
                languages: payload,
            }
        case SET_QUESTION_TYPES:
            return {
                ...state,
                questionTypes: payload,
            }
        case SET_ORG_LIST:
            return {
                ...state,
                orgList: payload,
            }
        case SET_ZONE_LIST:
            return {
                ...state,
                zoneList: payload,
            }
        case SET_QUESTION_RELATION_TYPES:
            return {
                ...state,
                questionRelationTypes: payload,
            }
        case SET_PROMPT_TPL:
            return {
                ...state,
                promptTpl: payload,
            }
        case SET_EXERCISES:
            return {
                ...state,
                exercises: payload,
            }
        case SET_AREA:
            return {
                ...state,
                area: payload,
            }
        case SET_EXERCISE_TYPE:
            return {
                ...state,
                exerciseTypes: payload,
            }
        case SET_KV:
            return {
                ...state,
                KV: { ...state.KV, ...payload },
            }
        case SET_CONSTANTS:
            return {
                ...state,
                constants: { ...state.constants, ...payload },
            }
        default:
            return state
    }
}
