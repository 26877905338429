import { formatUrl, request } from './index'

export default {
    getList: async (params) => {
        return request(formatUrl('/organizations', '', false), { params })
    },
    put: async (id, data) => {
        return request.put(formatUrl('/organizations/{id}', { id: id }, false), data)
    },
    getDetail: async (id) => {
        return request(formatUrl('/organizations/{id}', { id: id }, false))
    },
    post: async (data) => {
        return request.post(formatUrl('/organizations', {}, false), data)
    },
    delete: async (id) => {
        return request.delete(formatUrl(`/organizations/${id}`, {}, false), { data: {} })
    },
    postCharges: async (id, data) => {
        return request.post(formatUrl('/organizations/{id}/charges', { id }, false), data)
    },
    getCharges: async (id, params) => {
        return request(formatUrl('/organizations/{id}/charges', { id }, false), { params })
    }
}
